<!--轮播 -->
<template>
  <div class="switer_list">
    <div class="switer_left switer_icon" @click="leftClick">
      <img src="https://img.fanxinghuishou.cn/20231007/804157710.png" alt="">
    </div>
    <div style="overflow: hidden;width: 410px">
      <div class="switer_list_box" :style="videoSliderStyles" style="" v-if="switerList.length > 0">
        <div class="switer_inner" v-for="(item, index) in switerList" :key="index">
          <img @click="imgPreview(item.originalImg)" class="image_show" :src="item.smallImg" />
        </div>
      </div>
      <div v-else class="empty">
        <img src="../images/img_ddsh_.png" alt="">
        <p>暂无图片</p>
      </div>
    </div>
    <div class="switer_rigth switer_icon" @click="rightClick">
      <img src="https://img.fanxinghuishou.cn/20231007/247777825.png" alt="">
    </div>
  </div>
</template>

<script>
import details from "@/views/RecallOrder/Details.vue";

export default {
  name: "Carousel",
  data() {
    return {
      // 轮播
      videoSwiterData: {
        // 当前索引
        index: 0,
        // 移动位置
        x: 0
      },
      // video预览
      videoShow: false,
      videoUrl: "",
    }
  },
  props: {
    switerList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    details() {
      return details
    },
    videoSliderStyles() {
      return {
        transform: `translateX(${this.videoSwiterData.x}px)`
      }
    },
    imgUrl() {
      let imgUrl =[]
      this.switerList.forEach(element => {
        imgUrl.push(element.originalImg)
      });
      return imgUrl
    },
  },
  methods: {
    imgPreview(v) {
      const imgUrl = this.imgUrl;
      const index = imgUrl.findIndex(item => item === v);
      this.$viewerApi({
        images: this.imgUrl,
      }).view(index)
    },
    videoPreview(v) {
      this.videoShow = true;
      this.videoUrl = v;
    },
    leftClick() {
      const videoSwiterData = this.videoSwiterData;
      const switerList = this.switerList;
      if (!videoSwiterData.index) {
        this.videoSwiterData = {
          index: switerList.length - 1,
          x: -(switerList.length - 1) * 65
        }
      } else {
        this.videoSwiterData = {
          index: videoSwiterData.index - 1,
          x: videoSwiterData.x + 65
        }
      }
    },
    rightClick() {
      const videoSwiterData = this.videoSwiterData;
      const switerList = this.switerList;
      if (videoSwiterData.index === switerList.length - 1) {
        this.videoSwiterData = {
          index: 0,
          x: 0
        }
      } else {
        this.videoSwiterData = {
          index: videoSwiterData.index + 1,
          x: -((videoSwiterData.index + 1) * 65)
        }
      }
    },
  }
}
</script>


<style scoped lang="scss">
.switer_list {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  .switer_list_box {
    width: 99999999999px;
    display: flex;
    flex: 1;
    align-items: center;
    transition: all 0.5s;
  }

  .switer_inner {
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    border-radius: 6px;
    margin-right: 8px;
    max-width: 60px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    position: relative;
  }

  .mini_show {
    display: block;
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    object-fit: cover;
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
  }

  .video_mask {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999999999999;
    background: rgba(0, 0, 0, .3);
    cursor: pointer;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-size: 30px;
  }

  .switer_icon {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    cursor: pointer;
    img{
      width: 20px;
      height: 20px;
    }
   
  }

  .switer_left {
    left: 0;
    margin-right: 18px;
  }

  .switer_rigth {
    right: 0;
    margin-left: 18px;
  }
}

.video_preview {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, .5);
  left: 0;
  top: 0;
  z-index: 99999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .video_close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    background-color: #606266;
    border-radius: 50%;
    cursor: pointer;
  }
}
.image_show{
   height: 100%;
}

.empty {
  width: 100%;

  img {
    display: block;
    width: 96px;
    height: 50px;
    margin: 0 auto;
  }

  >p {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
}
</style>
