<template>
  <div>
    <div>
      <div class="button_top">
        <div class="faultBtn">
          <div
            :class="{ none: true, active: auditStatus === '00' }"
            @click="btnClick('00')"
          >
            待审核({{ allnumcount.waitNum || 0 }}个)
          </div>
          <div
            :class="{ none: true, active: auditStatus === '10' }"
            @click="btnClick('10')"
          >
            完美点评({{ allnumcount.perfectNum || 0 }}个)
          </div>
          <div
            :class="{ none: true, active: auditStatus === '20' }"
            @click="btnClick('20')"
          >
            规范提醒({{ allnumcount.standardNum || 0 }}个)
          </div>
          <div
            :class="{ none: true, active: auditStatus === '30' }"
            @click="btnClick('30')"
          >
            发起补拍({{ allnumcount.reimgNum || 0 }}个)
          </div>
          <div
            :class="{ none: true, active: auditStatus === '40' }"
            @click="btnClick('40')"
          >
            驳回报价({{ allnumcount.rejectNum || 0 }}个)
          </div>
          <div
            :class="{ none: true, active: auditStatus === '60' }"
            @click="btnClick('60')"
          >
            未审核({{ allnumcount.unAuditNum || 0 }}个)
          </div>
        </div>
        <div>
          <el-button
            type="warning"
            size="small"
            @click="Manuaexamination"
            :key="80"
            >人工审单商户</el-button
          >
        </div>
      </div>
      <div v-if="seachDataList.length > 0" v-loading="fullscreenLoading">
        <div class="table" v-for="(item, index) in seachDataList" :key="index">
          <div class="table_header">
            <div class="table_header_left">
              <p class="table_index">{{ index + 1 }}</p>
              <p>
                订单编号：<span
                  style="text-decoration:underline;cursor: pointer;"
                  @click="jumptoDetail(item.orderNo)"
                  :id="'order-no-' + index"
                  >{{ item.orderNo }}</span
                >
                <img
                  style="
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                    transform: translate(6px, 3px);
                  "
                  @click="
                    copy('order-no-' + index);
                    $message.success('复制成功');
                  "
                  src="../../../assets/images/fztb.png"
                  alt=""
                />
              </p>
              <p>询价商户：{{ item.companyName }}</p>
              <p>询价门店：{{ item.storeName || "--" }}</p>
              <p>店员：{{ item.staffName }}-{{ item.staffMobile }}</p>
              <p>
                订单状态：{{
                  item.state == "00"
                    ? "待确认"
                    : item.state == "01"
                    ? "已确认"
                    : item.state == "02"
                    ? "待支付"
                    : item.state == "03"
                    ? "已绑码"
                    : item.state == "04"
                    ? "已收货"
                    : item.state == "05"
                    ? "降价收货"
                    : item.state == "10"
                    ? "已取消"
                    : item.state == "20"
                    ? "已作废"
                    : "已退回"
                }}
              </p>
            </div>
            <div class="table_header_right">
              <div class="bicycle_lane" v-if="item.isNiceBiz">靓机</div>
              <div class="bicycle_lane Star_support" v-if="item.channelName">
                {{ item.channelName }}
              </div>
            </div>
          </div>
          <div>
            <el-table :data="[item]" border style="width: 100%" id="excelOrder">
              <el-table-column
                prop="name"
                label="旧机图片"
                width="530px"
                align="center"
              >
                <template slot-scope="{ row }">
                  <div class="images-box" v-if="row.images.length > 0">
                    <Carousel :switer-list="row.images" />
                  </div>
                  <div v-else>暂无图片</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="phoneName"
                width="110px"
                label="机型/规格"
                align="center"
              >
                <template slot-scope="{ row }">
                  <div>{{ row.phoneName || "--" }}</div>
                  <el-tag
                    v-if="row.phoneName"
                    type="danger"
                    size="small"
                    style="cursor: pointer"
                    @click="resetoldmachine(row)"
                    >修改旧机信息</el-tag
                  >
                  <p
                    v-if="row.phoneUpdateCount"
                    style="color:#FF687B;text-decoration:underline;font-size: 12px;cursor: pointer"
                    @click="seaRecord(row)"
                  >
                    {{ row.phoneUpdateCount }}条修改记录
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="phoneName" label="旧机串号" align="center">
                <template slot-scope="{ row }">
                  <div>{{ row.imei || "--" }}</div>
                  <p
                    v-if="row.imei"
                    class="lv_fc_line"
                    @click="serchFunction(row.imei)"
                  >
                    查询串号
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="phoneName" label="功能情况" align="center">
                <template slot-scope="{ row }">
                  <p
                    class="lv_fc_line"
                    @click="getFunction(row.orderNo, row.outType)"
                  >
                    查看
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="typeName" label="预估价" align="center">
                <template slot-scope="{ row }">
                  <span>{{ row.channelAssessPrice || "--" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="店员备注" align="center">
                <template slot-scope="{ row }">
                  <div>{{ row.remark || "--" }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="quoteMerchants"
                label="参与报价回收商"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span
                    v-if="row.quoteMerchants && row.quoteMerchants != '--'"
                    style="cursor: pointer"
                    class="text-down"
                    @click="openStoreList(row)"
                    >{{ row.quoteMerchants }}</span
                  >
                  <span v-else>{{ row.quoteMerchants }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="diffFundPrice"
                label="未审单原因"
                align="center"
                v-if="auditStatus === '60'"
              >
                <template slot-scope="{ row }">
                  <span>
                    订单{{
                      row.state == "00"
                        ? "待确认"
                        : row.state == "01"
                        ? "已确认"
                        : row.state == "02"
                        ? "待支付"
                        : row.state == "03"
                        ? "已绑码"
                        : row.state == "04"
                        ? "已收货"
                        : row.state == "05"
                        ? "降价收货"
                        : row.state == "10"
                        ? "已取消"
                        : row.state == "20"
                        ? "已作废"
                        : "已退回"
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="ackTime"
                label="取消/确认时间"
                align="center"
                v-if="auditStatus === '60'"
              >
                <template slot-scope="{ row }">
                  <span v-if="row.ackTime || row.cancelTime">{{
                    row.ackTime || row.cancelTime
                  }}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="auditStatus !== '00' && auditStatus !== '60'"
                prop="estimateAdminName"
                label="审单人"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span
                    >{{ row.estimateAdminName || "-" }}-{{
                      row.estimateAdminAcc || ""
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="estimateTime"
                label="审单时间"
                align="center"
                v-if="auditStatus !== '00' && auditStatus !== '60'"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.estimateTime || "--" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="auditStatus === '30'"
                prop="name"
                label="补拍图片"
                align="center"
              >
                <template slot-scope="{ row }">
                  <div
                    class="images-box"
                    v-if="
                      row.estimateReimgSampleImages &&
                        row.estimateReimgSampleImages.length > 0
                    "
                  >
                    <img
                      :src="row.estimateReimgSampleImages[0]"
                      alt=""
                      style="width: 50px; height: 60px"
                    />
                    <p
                      @click="openImgBox(row.estimateReimgSampleImages, 1)"
                      class="text-down"
                    >
                      共{{ row.estimateReimgSampleImages.length }}张
                    </p>
                  </div>
                  <div v-else>暂无图片</div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="auditStatus === '30'"
                prop="name"
                label="是否完成补拍"
                align="center"
              >
                <template slot-scope="{ row }">
                  <div class="images-box" v-if="row.estimateReimgState == '01'">
                    <p>是</p>
                    <span @click="openImgBox(row.reImages, 2)" class="text-down"
                      >共{{ row.reImages.length }}张</span
                    >
                  </div>
                  <div v-else>否</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hasExpected"
                label="点评内容"
                align="center"
                v-if="auditStatus !== '00' && auditStatus !== '60'"
              >
                <template slot-scope="{ row }">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="row.estimateContent"
                    placement="top"
                  >
                    <div class="content_copy">{{ row.estimateContent }}</div>
                  </el-tooltip>
                  <span
                    v-if="row.estimateImages.length > 0"
                    style="cursor: pointer"
                    class="text-down"
                    @click="openExpected(row.estimateImages)"
                    >查看图片</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="210"
                v-if="auditStatus === '00'"
              >
                <template slot-scope="{ row }">
                  <el-button
                    @click="handleClick(row, 'remind')"
                    type="primary"
                    size="mini"
                    >规范提醒</el-button
                  >
                  <el-button
                    style="margin-left: 10px; margin-top: 10px"
                    @click="handleClick(row, 'remark')"
                    type="success"
                    size="mini"
                    >完美点评
                  </el-button>
                  <el-button
                    style="margin-left: 0px; margin-top: 10px"
                    @click="handleClick(row, 'reshot')"
                    type="warning"
                    size="mini"
                    >发起补拍
                  </el-button>
                  <el-button
                    style="margin-left: 10px; margin-top: 10px"
                    @click="handleClick(row, 'reject')"
                    type="danger"
                    size="mini"
                    >驳回报价
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="orderPagina">
          <el-pagination
            background
            :current-page="page.pageNum"
            :page-size="10"
            layout="total, prev, pager, next,jumper"
            :total="page.total"
            class="el-pagination-c"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div v-else class="emptyOrder">
        暂无订单~
      </div>
    </div>
    <!-- 修改旧机信息 -->
    <el-dialog
      title="修改旧机信息"
      :visible.sync="csetionShow"
      :close-on-click-modal="false"
      width="700px"
    >
      <div class="exampleModify dis_c ju_s">
        <div class="em_left">
          <div class="eml_center">
            品牌: {{ Oldmachineinformation.brandName || "--" }}
          </div>
          <div class="eml_center">
            机型: {{ Oldmachineinformation.phoneName || "--" }}
          </div>
          <div class="eml_center" v-if="orhaveIos">
            规格:{{ Oldmachineinformation.phoneStorage || "--" }}
          </div>
          <div class="eml_center" v-if="!orhaveIos">
            运行内存：{{ Oldmachineinformation.phoneMemory || "--" }}
          </div>
          <div class="eml_center" v-if="!orhaveIos">
            储存空间：{{ Oldmachineinformation.phoneStorage || "--" }}
          </div>
          <!-- <div class="eml_center">串号: {{ Oldmachineinformation.imei }}</div> -->
          <div class="Current_information">当前信息</div>
        </div>
        <div class="em_right">
          <div class="change_information">修改信息</div>
          <el-form :model="createinitData" ref="ruleForm">
            <el-form-item label="品牌:" label-width="75px" prop="brandNo">
              <el-select
                size="small"
                v-model="brandNo"
                placeholder="请选择或输入搜索"
                clearable
                filterable
                @change="selecbrandChange"
              >
                <el-option
                  v-for="item in shanpsSelectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.brandNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="机型:" label-width="75px" prop="modelCode">
              <el-select
                size="small"
                v-model="modelCode"
                placeholder="请选择或输入搜索"
                clearable
                filterable
                @change="selectmaicelChange"
              >
                <el-option
                  v-for="item in phoneSelectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.modelNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="规格:"
              v-if="flagIos"
              label-width="75px"
              prop="phoneStorageId"
            >
              <el-select
                size="small"
                v-model="phoneStorageId"
                placeholder="请选择或输入搜索"
                clearable
                filterable
                allow-create
                default-first-option
                @blur="onTypeBlur($event)"
                @change="selecthandleChange"
              >
                <el-option
                  v-for="item in skuSelectList"
                  :key="item.id"
                  :label="item.specName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="运行内存:"
              v-if="!flagIos"
              label-width="75px"
              prop="phoneMemoryId"
            >
              <el-select
                size="small"
                v-model="phoneMemoryId"
                placeholder="请选择或输入搜索"
                clearable
                filterable
                allow-create
                default-first-option
                @blur="merTypeBlur($event)"
                @change="selecthandleChange"
              >
                <el-option
                  v-for="item in operatSelectList"
                  :key="item.id"
                  :label="item.specName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="储存空间:"
              v-if="!flagIos"
              label-width="75px"
              prop="phoneStorageId"
            >
              <el-select
                size="small"
                v-model="phoneStorageId"
                placeholder="请选择或输入搜索"
                clearable
                filterable
                allow-create
                default-first-option
                @blur="onTypeBlur($event)"
                @change="selecthandleChange"
              >
                <el-option
                  v-for="item in skuSelectList"
                  :key="item.id"
                  :label="item.specName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="串号:" label-width="75px" prop="content">
              <div style="width: 215px; display: inline-block">
                <el-input
                  size="small"
                  autosize
                  :maxlength="200"
                  v-model="imei"
                  placeholder="请输入串号"
                  clearable
                >
                </el-input>
              </div>
            </el-form-item> -->
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForm')">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="btnLoading"
          @click="submitAdd('ruleForm')"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <!-- 完美点评 -->
    <el-dialog
      title="完美点评"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="commandClosed"
    >
      <div class="top_title">
        说明：店员拍照清晰，选择旧机信息完善，提交后用于鼓励店员，可提醒店员发货/退账号等额外事宜
      </div>
      <div>
        <p><span style="color:red;margin-right:5px">*</span>填写点评内容</p>
        <div class="qb_act">
          <div
            class="qukliy_box"
            @click="remarkAdd('照片拍摄清晰完整，棒棒的！')"
          >
            照片拍摄清晰完整，棒棒的！
          </div>
          <div class="qukliy_box" @click="remarkAdd('使用系统检测报价更高哦~')">
            使用系统检测报价更高哦~
          </div>
        </div>
        <div>
          <el-input
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="100"
            type="textarea"
            placeholder="请输入原因说明（限100字）"
            v-model="PerfectreviewData.joinCause"
          ></el-input>
        </div>
      </div>
      <div style="margin-top: 20px">
        <p><span style="color:red;margin-right:5px">*</span>选择鼓励图片</p>
        <div class="Encourag_picture">
          <div
            class="ep_image"
            @click="() => (ngImgidex = index)"
            v-for="(item, index) in ncouragpictureList"
            :key="index"
          >
            <img :src="item" alt="" />
            <img
              v-if="ngImgidex === index"
              class="imgredion"
              src="https://img.fanxinghuishou.cn/20231007/628857761.png"
              alt=""
            />
            <img
              v-else
              class="imgredion"
              src="https://img.fanxinghuishou.cn/20231007/315652687.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" @click.native="usedSuccess('remark')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 规范提醒 -->
    <el-dialog
      title="规范提醒"
      :visible.sync="SpereminderShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="commandClosed"
    >
      <div class="top_title">
        说明：店员上传照片完整，但因拍摄角度/灯光遮挡/未清洁撕膜可能导致报价保留时进行提醒。
      </div>
      <div>
        <p><span style="color:red;margin-right:5px">*</span>填写提醒内容</p>
        <div class="qb_act">
          <div class="qukliy_box" @click="remarkAdd('撕膜拍摄，报价更高')">
            撕膜拍摄，报价更高
          </div>
          <div
            class="qukliy_box"
            @click="remarkAdd('要想询价高，清洁屏幕第一步！')"
          >
            要想询价高，清洁屏幕第一步！
          </div>
        </div>
        <div>
          <el-input
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="100"
            type="textarea"
            placeholder="请输入原因说明（限100字）"
            v-model="PerfectreviewData.joinCause"
          ></el-input>
        </div>
      </div>
      <div style="margin-top: 20px">
        <p><span style="color:red;margin-right:5px">*</span>上传提醒照片</p>
        <div class="Encourag_picture" v-if="SpereminderShow">
          <JudgementsList
            :is-reset="isResponsibilityReset"
            :source-data="submitData.judgements"
            @dataChange="(v) => standardChange(v)"
            :assignmentData="assignmentData"
            :state="imgdetailData.state"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="SpereminderShow = false">取消</el-button>
        <el-button type="primary" @click.native="usedSuccess('remind')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 驳回报价 -->
    <el-dialog
      title="驳回报价"
      :visible.sync="RejectofferShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="commandClosed"
    >
      <div class="top_title">
        说明：店员拍照有重大问题/填写旧机信息与实际上传货不对版/背景杂乱疑似维修店，驳回后该订单只能取消订单或重新评估
      </div>
      <div>
        <p><span style="color:red;margin-right:5px">*</span>驳回报价原因</p>
        <div class="qb_act">
          <div class="qukliy_box" @click="remarkAdd('照片乱拍，无法报价')">
            照片乱拍，无法报价
          </div>
          <div class="qukliy_box" @click="remarkAdd('照片违规，请重新下单')">
            照片违规，请重新下单
          </div>
        </div>
        <div>
          <el-input
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="100"
            type="textarea"
            placeholder="请输入原因说明（限100字）"
            v-model="PerfectreviewData.joinCause"
          ></el-input>
        </div>
      </div>
      <div style="margin-top: 20px">
        <p><span style="color:red;margin-right:5px">*</span>上传照片</p>
        <div class="Encourag_picture" v-if="RejectofferShow">
          <JudgementsList
            :is-reset="isResponsibilityReset"
            :source-data="submitData.judgements"
            @dataChange="(v) => rejectChange(v)"
            :assignmentData="assignmentData"
            :state="imgdetailData.state"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="RejectofferShow = false">取消</el-button>
        <el-button type="primary" @click.native="usedSuccess('reject')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 发起补拍 -->
    <el-dialog
      title="发起补拍"
      :visible.sync="InitiatereshootShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="commandClosed"
    >
      <div class="top_title">
        说明：店员拍照缺失或不清晰，发起后回收商报价将失效，店员重新补拍后需要回收商再次报价。
      </div>
      <div>
        <p><span style="color:red;margin-right:5px">*</span>选择补拍图片</p>
        <div class="Encourag_picture">
          <div
            class="ep_image"
            v-for="(item, index) in reshootimgList"
            :key="index"
            @click="
              () =>
                (reshootimgList[index].isSelected = !reshootimgList[index]
                  .isSelected)
            "
          >
            <img :src="item.sampleImage" alt="" />
            <img
              v-if="item.isSelected"
              class="imgredion"
              src="https://img.fanxinghuishou.cn/20231007/687013453.png"
              alt=""
            />
            <img
              v-else
              class="imgredion"
              src="https://img.fanxinghuishou.cn/20231007/187057703.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <p><span style="color:red;margin-right:5px">*</span>填写提醒内容</p>
        <div class="qb_act">
          <div class="qukliy_box" @click="remarkAdd('关于本机白色背景照片')">
            关于本机白色背景照片
          </div>
          <div
            class="qukliy_box"
            @click="remarkAdd('请补充面容/指纹重新录入完成的页面')"
          >
            请补充面容/指纹重新录入完成的页面
          </div>
        </div>
        <div>
          <el-input
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="100"
            type="textarea"
            placeholder="请输入原因说明（限100字）"
            v-model="PerfectreviewData.joinCause"
          ></el-input>
        </div>
      </div>
      <div style="margin-top: 20px">
        <p>上传提醒照片(非必填)</p>
        <div class="Encourag_picture" v-if="InitiatereshootShow">
          <JudgementsList
            :is-reset="isResponsibilityReset"
            :source-data="submitData.judgements"
            @dataChange="(v) => judgementsChange(v)"
            :assignmentData="assignmentData"
            :state="imgdetailData.state"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="InitiatereshootShow = false">取消</el-button>
        <el-button type="primary" @click.native="usedSuccess('reshot')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看参与报价回收商 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`80%`"
      title="查看参与报价回收商"
      :visible="storeShow"
      @close="storeClose"
      top="15%"
    >
      <div class="img-box">
        <p class="lv_fc_red" style="margin-bottom: 10px">
          说明：若该门店商户预留差异基金，则门店所看到的报价金额需加上差异基金才是商户的初始报价
        </p>
        <div class="table_list">
          <el-table
            :data="storeList"
            border
            style="width: 100%"
            id="excelOrder"
            :cell-style="rowStyle"
            @cell-click="Bargain"
          >
            <el-table-column
              prop="index"
              type="index"
              label="序号"
            ></el-table-column>
            <el-table-column
              prop="recycleMerchantName"
              label="报价商家"
              align="center"
              width="200"
            >
              <template scope="{ row }">
                <el-tag
                  v-if="row.recycleMerchantName == merchantName"
                  size="mini"
                  type="danger"
                  effect="dark"
                  style="margin-right: 10px"
                >
                  成交
                </el-tag>
                <span>{{ row.recycleMerchantName }}</span>
                <div>
                  <el-tag
                    v-if="
                      row.isFixHighQuote &&
                        row.recycleMerchantName != merchantName &&
                        row.isFixHighQuote != '--'
                    "
                    size="mini"
                    type="warning"
                    effect="dark"
                    style="margin-right: 10px"
                  >
                    首次取价
                  </el-tag>
                  <el-tag
                    v-if="
                      row.isFixExpPrice &&
                        row.recycleMerchantName != merchantName &&
                        row.isFixExpPrice != '--'
                    "
                    size="mini"
                    type="warning"
                    effect="dark"
                  >
                    议价取价
                  </el-tag>
                  <el-tag
                    v-if="row.isAddPrice && row.isAddPrice != '--'"
                    size="mini"
                    type="success"
                    effect="dark"
                  >
                    平台加价
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="quotePrice"
              width="100"
              label="报价金额"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="exclusiveTime"
              width="100"
              label="抢单时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="quoteTime"
              width="180"
              label="最新报价时间"
              align="center"
            ></el-table-column>
            <el-table-column prop="staffName" label="报价人" align="center">
              <template scope="{ row }">
                {{ row.staffName }}
                <span v-if="row.staffMobile && row.staffMobile != '--'"
                  >- {{ row.staffMobile }}</span
                >
                <span v-if="row.staffType && row.staffType != '--'"
                  >（{{ row.staffType }}）</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="quoteNum"
              width="100"
              label="报价次数"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="state"
              width="100"
              label="报价状态"
              align="center"
            >
              <template scope="{ row }">
                <span>{{
                  row.state == "00-0"
                    ? "待报价"
                    : row.state == "00-1"
                    ? "报价中"
                    : row.state == "00-2"
                    ? "已报价"
                    : row.state == "01"
                    ? "已确认"
                    : row.state == "10" && row.exclusiveCancelTime != "--"
                    ? `取消抢单${row.exclusiveCancelTime}`
                    : "交易取消"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="isNiceDevice"
              width="100"
              label="是否标记靓机"
              align="center"
            >
              <template scope="{ row }">
                <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quoteNote"
              width="100"
              label="报价备注"
              align="center"
            >
              <template scope="{ row }">
                <el-tooltip
                  v-if="row.quoteNote != '--'"
                  class="item"
                  effect="dark"
                  :content="row.quoteNote == '--' ? '' : row.quoteNote"
                  placement="bottom"
                >
                  <span class="quoteNotecss">{{ row.quoteNote }}</span>
                </el-tooltip>
                <span v-else>{{ row.quoteNote }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="originalQuotePrice"
              width="100"
              label="报价金额（含差异基金）"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="diffFundPrice"
              width="100"
              label="差异基金"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="quoteChannelServerFee"
              width="100"
              label="渠道服务费"
              align="center"
            >
              <template scope="{ row }">
                {{ row.quoteChannelServerFee }}
                <div
                  v-if="
                    row.quoteChannelServerFee &&
                      row.quoteChannelServerFee != '--'
                  "
                  style="color: red"
                >
                  {{ row.matchServerFeeOnline == "01" ? "线上" : "线下" }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 查看修改记录 -->
    <el-dialog
      :close-on-click-modal="false"
      width="700px"
      title="查看修改记录"
      :visible="amendantrecordshow"
      @close="storeClose"
      top="15%"
    >
      <div class="img-box">
        <div>
          <p style="margin-bottom:10px">订单原信息</p>
          <div class="basi_information">
            <span>品牌: {{ Oldmachineinformation.brandName }} </span>
            <span>机型: {{ Oldmachineinformation.phoneName }} </span>
            <span
              >规格: {{ Oldmachineinformation.phoneStorage
              }}{{
                Oldmachineinformation.phoneMemory
                  ? "+" + Oldmachineinformation.phoneMemory
                  : ""
              }}
            </span>
            <span>串号: {{ Oldmachineinformation.imei }} </span>
          </div>
        </div>
        <div class="table_list">
          <p style="margin:10px 0">修改记录</p>
          <el-table
            :data="phoneRecoderList"
            border
            style="width: 100%"
            id="excelOrder"
          >
            <el-table-column
              prop="index"
              type="index"
              label="序号"
            ></el-table-column>
            <el-table-column
              prop="updateAdminName"
              label="修改人"
              align="center"
            >
              <template scope="{ row }">
                <span>{{ row.updateAdminName }}-{{ row.updateAdminAcc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="修改时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="updateAfterParam"
              label="修改后规格信息"
              align="center"
            >
              <template scope="{ row }">
                <span
                  :style="{ color: item.isUpdate ? '#FF687B' : '' }"
                  class="cv_changgetext"
                  v-for="(item, index) in row.updateAfterParam"
                  :key="index"
                  >{{ item.label }}：{{ item.value }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="amendantrecordshow = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 查看补拍照片 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看补拍照片"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-tips-box">
          <div
            v-if="DialogImgList.length > 0"
            class="img-tips"
            :class="imgShowOnly ? 'img-tips-default' : 'img-tips-choose'"
          >
            补拍照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList.length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList.length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item v-for="item in DialogImgList" :key="item.id">
                  <div class="sec_img">
                    <img :src="item" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 人工审单配置 -->
    <el-dialog
      title="人工审单配置"
      :visible.sync="SetcategoryDloding"
      :close-on-click-modal="false"
      width="1100px"
      @closed="receiptTypeClosed"
    >
      <div class="button_top">
        <div class="faultBtn">
          <div
            :class="{ none: true, active: setType === '00' }"
            @click="setTyClick('00')"
          >
            按商户配置
          </div>
          <div
            :class="{ none: true, active: setType === '01' }"
            @click="setTyClick('01')"
          >
            按订单配置
          </div>
        </div>
      </div>
      <div style="margin-bottom:20px" v-if="setType === '00'">
        <div class="top_buttomsty dis_flex">
          <div>
            <el-button
              type="danger"
              size="small"
              v-if="multipleSelection.length > 0"
              @click="delClick()"
              :key="70"
              >批量移除商户</el-button
            >
            <el-button type="info" size="small" v-else :key="80"
              >批量移除商户</el-button
            >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="Addcommercialtenant"
              >添加商家</el-button
            >
          </div>
        </div>
        <el-table
          ref="multipleTable"
          :data="setlistData"
          border
          tooltip-effect="dark"
          style="width: 100%"
          max-height="590px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="70"
            :index="(index) => index + 1"
          />
          <el-table-column prop="companyName" align="center" label="商户名称">
          </el-table-column>
          <el-table-column prop="staffName" align="center" label="创建人">
          </el-table-column>
          <el-table-column prop="staffPhone" align="center" label="联系电话">
          </el-table-column>
          <el-table-column prop="inTime" align="center" label="入驻时间">
          </el-table-column>
          <el-table-column
            prop="storeNum"
            align="center"
            label="门店数量"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button size="mini" type="danger" @click="delClick(row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else style="width: 950px;">
        <div style="max-height: 480px;overflow: auto;">
          <div class="dis_flex al_c set_top_ck">
            <span>选择报价分类/品牌</span>
          </div>
          <div
            class="categorySet"
            v-for="(item, index) in alltypeData"
            :key="index"
          >
            <div class="cs_center dis_flex al_c ju_sb">
              <div class="selection_type" @click="allSction(item)">
                <img
                  v-if="item.selected"
                  src="https://img.fanxinghuishou.cn/20231129/601742868.png"
                  alt=""
                />
                <img
                  v-else
                  src="https://img.fanxinghuishou.cn/20231129/523821044.png"
                  alt=""
                />
                <span>{{ item.name }}</span>
              </div>
            </div>
            <div style="margin: 15px 0;"></div>
            <div style="padding: 0 25px;display: flex;flex-wrap: wrap;">
              <div
                class="selection_type"
                style="margin-bottom: 10px;"
                v-for="(city, ctindex) in item.brands"
                :key="ctindex"
                @click="flagSction(city, item)"
              >
                <img
                  v-if="city.selected"
                  src="https://img.fanxinghuishou.cn/20231129/601742868.png"
                  alt=""
                />
                <img
                  v-else
                  src="https://img.fanxinghuishou.cn/20231129/523821044.png"
                  alt=""
                />
                <span>{{ city.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="dis_flex al_c set_top_ck">
            <span>选择旧机使用情况</span>
          </div>
          <div class="categorySet">
            <div class="cs_center dis_flex al_c">
              <el-checkbox
                :indeterminate="quoteMachineHealthisIndeterminate"
                v-model="quoteMachineHealthcheckAll"
                @change="quoteMachineHealthallChange"
                >全选</el-checkbox
              >
            </div>
            <div style="margin: 15px 0"></div>
            <div style="padding: 0 25px">
              <el-checkbox-group
                v-model="quoteMachineHealthcheckedCities"
                @change="quoteMachineHealthChange"
              >
                <el-checkbox
                  v-for="city in quoteMachineHealthcities"
                  :label="city.value"
                  :key="city.value"
                  >{{ city.value }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div>
          <div class="dis_flex al_c set_top_ck">
            <span>按旧机预估价配置</span>
          </div>
          <div class="categorySet">
            <div class="cs_center dis_flex al_c">
              <el-checkbox
                :indeterminate="machinePrePriceisIndeterminate"
                v-model="machinePrePricecheckAll"
                @change="machinePrePriceallChange"
                >全选</el-checkbox
              >
            </div>
            <div style="margin: 15px 0"></div>
            <div style="padding: 0 25px">
              <el-checkbox-group
                v-model="machinePrePricecheckedCities"
                @change="machinePrePriceChange"
              >
                <el-checkbox
                  v-for="city in machinePrePricecities"
                  :label="city.value"
                  :key="city.value"
                  >{{ city.value }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="setType !== '00'">
        <el-button @click.native="SetcategoryDloding = false">取消</el-button>
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="SetcategorySubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 商户配置动态口令弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="tantDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <div style="text-align: center; font-size: 16px; margin-bottom: 20px">
        是否确认将以上<span style="color: #ff8080;"
          >{{ checkList.length }}个</span
        >商户添加到人工审单？
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="tantDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="tenantSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 订单配置动态口令弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="quotationDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <div style="text-align: center; font-size: 16px; margin-bottom: 20px">
        是否确认保存审单配置？
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="quotationDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="quotationSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 移除配置动态口令弹窗 -->
    <el-dialog
      title="移除提示"
      :visible.sync="sublicenseDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <div style="text-align: center; font-size: 16px; margin-bottom: 20px">
        是否确认将商户从人工审单商户中移除？
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="sublicenseDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="subjectSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加商户 -->
    <el-dialog
      title="添加商户"
      :visible.sync="Addpartnershow"
      :close-on-click-modal="false"
      width="800px"
      @closed="Addpartnerclose"
    >
      <div
        class="addparto"
        style="display: flex;flex-direction: row;align-items: center;"
      >
        <img
          style="width:14px;height:14px;margin-right:5px;"
          src="../../../assets/images/ico_ts_red (2).png"
        />
        说明:输入门店商户名称进行模糊查询，选中后点击确认完成添加。
      </div>
      <el-form
        :inline="true"
        class="demo-form-inline"
        size="small"
        style="margin-top: 13px"
      >
        <el-form-item label="商户名称">
          <el-input
            v-model="username"
            clearable
            placeholder="请输入门店商名称查询"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns2"
        :data="tableData"
        :currentPage="page2.pageNum"
        :total="page2.total"
        :pageSize="5"
        :isSelection="true"
        :isIndex="false"
        @handleSelectionChange="cooperationChange"
        @handleCurrentChange="handleCurrentChange2"
      >
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            username = '';
            Addpartnershow = false;
            checkList = [];
          "
          >取消</el-button
        >
        <el-button type="primary" @click.native="Addpartitle">确定</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
    <!-- 旧机功能情况 -->
    <el-dialog
      v-bind="imeiTestProps"
      :before-close="() => (imeiTestProps.show = false)"
      :visible.sync="imeiTestProps.show"
    >
      <div v-loading="imeiTestProps.isLoadding" class="function_box">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo :datainfo="detailData" />
        </div>
        <div>
           <!-- 估价单验机报告 -->
           <div class="test-box">
            <div class="test-result" v-if="detailData.functionView.errorList.length">
              <Title :title="'异常项（共'+ detailData.functionView.errorList.length +'项）'" />
              <!-- 旧机无系统检测 -->
              <div class="mb10" v-if="detailData.unableDetectionCause &&
                detailData.detectionType == '01'
                ">
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div class="mb10" style="display: flex;justify-content: space-between;"
                v-if="detailData.detectionType == '02'">
                <span class="fs16">检测机型：{{ detailData.detectionModel || "--" }}</span>
                <span class="fs16">录入串号：{{ detailData.imei || "--" }}</span>
              </div>
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.errorList"></TestTable>
            </div>
            <div class="test-result" style="margin-top:20px" v-if="detailData.functionView.normalList.length">
              <Title :title="'正常项（共'+ detailData.functionView.normalList.length +'项）'" />
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.normalList"></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0">
          <Title title="店员备注" />
          <Remark
            :remark="detailData.remark"
            :againPhotoRemark="detailData.againPhotoRemark"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button
          v-if="imeiTestProps.type === '01'"
          @click="imeiTestProps.show = false"
          plain
          type="primary"
          >关闭
        </el-button>
        <div v-else>
          <el-button @click="imeiTestProps.show = false">取 消</el-button>
          <el-button type="primary" @click="imeiTestProps.show = false"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看报价记录"
      :visible="viewrecords"
      @close="viewrecordsClose"
      top="15%"
      bottom="10%"
    >
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table
          :data="getUpdatedQuoteLog"
          max-height="300"
          border
          style="width: 100%"
          id="updatedOrder"
        >
          <el-table-column
            prop="rank"
            width="70"
            label="报价次序"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="quoteTime"
            width="160"
            label="报价时间"
            align="center"
          ></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span
                >{{ row.exclusiveQuoterName }}-{{
                  row.isMajor
                    ? "创建人"
                    : row.positionType == "01"
                    ? "子账号"
                    : "报价师"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag
                  type="danger"
                  size="small"
                  effect="plain"
                  v-if="row.isInside"
                  >内部加价</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.quoteNote"
                class="item"
                effect="dark"
                :content="row.quoteNote"
                placement="top"
              >
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column
            prop="originalQuotePrice"
            width="100"
            label="报价金额（含差异基金）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="diffFundPrice"
            width="100"
            label="差异基金"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="quoteChannelServerFee"
            width="100"
            label="渠道服务费"
            align="center"
          ></el-table-column>
        </el-table>
        <div
          style="color: #0981ff; font-size: 16px; margin: 20px 0"
          v-if="remakeQuoteLog && remakeQuoteLog.length"
        >
          补拍后报价记录
        </div>
        <el-table
          v-if="remakeQuoteLog && remakeQuoteLog.length"
          :data="remakeQuoteLog"
          max-height="300"
          border
          style="width: 100%"
          id="remakeOrder"
        >
          <el-table-column
            prop="rank"
            width="70"
            label="报价次序"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remakeTime"
            width="100"
            label="补拍时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="quoteTime"
            width="100"
            label="报价时间"
            align="center"
          ></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span
                >{{ row.exclusiveQuoterName }}-{{
                  row.isMajor
                    ? "创建人"
                    : row.positionType == "01"
                    ? "子账号"
                    : "报价师"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag
                  type="danger"
                  size="small"
                  effect="plain"
                  v-if="row.isInside"
                  >内部加价</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.quoteNote"
                class="item"
                effect="dark"
                :content="row.quoteNote"
                placement="top"
              >
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column
            prop="originalQuotePrice"
            width="100"
            label="报价金额（含差异基金）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="diffFundPrice"
            width="100"
            label="差异基金"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="quoteChannelServerFee"
            width="100"
            label="渠道服务费"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import JudgementsList from "../componets/JudgementsList.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import Carousel from "../componets/Carousel.vue";
import _api from "@/utils/request";
import moment from "moment";
import { MessageBox } from "element-ui";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import Title from "@/views/RecallOrder/componets/Title.vue";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";
import { imeiTestProps } from "@/views/RecallOrder/initState";
export default {
  components: {
    JudgementsList,
    TestTable,
    TestDeviceInfo,
    Title,
    Remark,
    ElImageViewer,
    Carousel,
  },
  data() {
    return {
      fullscreenLoading: false,
      MBList: [
        {
          name: "G",
          id: "G",
        },
        {
          name: "T",
          id: "T",
        },
      ],
      mbId: "G",
      moryId: "G",
      viewrecords: false,
      getUpdatedQuoteLog: [],
      remakeQuoteLog: [],
      Oldmachineinformation: {}, //旧机信息
      imei: "", //串号
      brandNo: "", //品牌
      modelCode: "", //机型
      phoneMemoryId: "", //运行内存
      phoneStorageId: "", //储存空间
      machineTypeId: null, //机型id
      flagIos: true, //是否苹果系统
      orhaveIos: true, //是否苹果系统
      orderNo: "",
      sublicenseDialogVisible: false,
      paramData: {
        companyIdRemove: [],
      },
      tableData: [],
      tableColumns2: [
        { label: "门店商名称", prop: "companyName" },
        { label: "联系人", prop: "contact" },
        { label: "电话", prop: "phone" },
        { label: "地址", prop: "address" },
        { label: "入驻时间", prop: "createTime" },
        { slotName: "auditStatus" },
      ],
      page2: {
        pageSize: 5,
        total: 0,
        pageNum: 1,
      },
      username: "",
      Addpartnershow: false,
      multipleSelection: [],
      checkList: [],
      setlistData: [],
      setType: "00",
      receiptTypeBtnLoading: false,
      quotationDialogVisible: false,
      tantDialogVisible: false,
      command: "",
      tupeIndex: "",
      alltypeData: [], //所有数据
      //按旧机预估价配置
      machinePrePricecheckAll: false,
      machinePrePricecheckedCities: [],
      machinePrePricecities: [],
      machinePrePriceisIndeterminate: true,
      machinePrePriceOptions: [],
      //选择旧机使用情况
      quoteMachineHealthcheckAll: false,
      quoteMachineHealthcheckedCities: [],
      quoteMachineHealthcities: [],
      quoteMachineHealthisIndeterminate: true,
      quoteMachineHealthOptions: [],
      //报价品类配置弹窗
      SetcategoryDloding: false,
      imgShow: false,
      imgShowOnly: true,
      DialogImgList: [],
      imgShowIndex: 0,
      imgIndex: 0,
      merchantName: "",
      storeShow: false,
      amendantrecordshow: false, //修改记录
      phoneRecoderList: [],
      storeList: [], //参与报价回收商
      // 上传依据直接赋值操作
      submitData: {
        // 判责依据
        judgements: [],
      },
      isResponsibilityReset: false,
      assignmentData: [],
      // 详情数据
      imgdetailData: {
        cview: {
          auditStaffInfo: "",
        },
        state: "00",
      },
      commandShow: false,
      SpereminderShow: false,
      RejectofferShow: false,
      InitiatereshootShow: false,
      ncouragpictureList: [
        "https://img.fanxinghuishou.cn/20231012/011536728.jpg",
        "https://img.fanxinghuishou.cn/20231012/570421585.jpg",
        "https://img.fanxinghuishou.cn/20231012/333615838.jpg",
      ], //鼓励图片
      reshootimgList: [],
      ngImgidex: null,
      imeiTestProps,
      PerfectreviewData: {
        joinCause: "",
        images: [],
      },
      copyType: "",
      shanpsSelectList: [], //品牌
      phoneSelectList: [], //机型
      skuSelectList: [], //规格
      operatSelectList: [], //运行内存
      allnumcount: {
        perfectNum: 0,
        reimgNum: 0,
        rejectNum: 0,
        standardNum: 0,
        unAuditNum: 0,
        waitNum: 0,
      },
      auditStatus: "00",
      // 功能情况接口
      detailData: {
        functionView:{
          normalList:[],
          errorList:[],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: [],
      },
      externalorderType: "", //订单类型
      pages: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      usedDialogVisible: false,
      imgList: [],
      showViewer: false,
      btnLoading: false,
      csetionShow: false,
      createinitData: {},
      createformRules: {
        copyType: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        content: [
          {
            required: true,
            trigger: "blur",
            message: "请输入分享文案内容",
            max: 200,
          },
        ],
      },
      entryTime: null,
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      datalist: [],
      tableColumns: [
        { label: "文案编号", prop: "copyNo" },
        { slotName: "copyType" },
        { slotName: "content" },
        { slotName: "posterImg" },
        { label: "下载量", prop: "downloadNum" },
        { label: "添加人", prop: "createAdminName" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "lastPutTime" },
        { slotName: "operation" },
      ],
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      copyNo: "",
      isPut: false,
    };
  },
  created() {
    let startTime = moment()
      .day(moment().day() - 6)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    startTime = Date.parse(startTime);
    endTime = Date.parse(endTime);
    this.entryTime = [startTime, endTime];
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      this.handleCurrentChange();
    },
  },
  methods: {
    //选中分类品牌
    flagSction(data, alldata) {
      data.selected = !data.selected;
      //判断是否触发全选样式展示
      let sumSection = [];
      sumSection = alldata.brands.filter((item) => item.selected);
      if(sumSection.length===alldata.brands.length){
        alldata.selected=true
      }else{
        alldata.selected=false
      }
    },
    //全选分类品牌
    allSction(data) {
      data.selected = !data.selected;
      if (data.selected) {
        data.brands.forEach((item) => {
          item.selected = true;
        });
      } else {
        data.brands.forEach((item) => {
          item.selected = false;
        });
      }
    },
    jumptoDetail(orderNo) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    onTypeBlur(e) {
      console.log(e.target.value);
      if (e.target.value.trim() !== "") {
        //e.target.value就是录入的内容
        this.phoneStorageId = e.target.value;
      }
    },
    merTypeBlur(e) {
      console.log(e);
      if (e.target.value.trim() !== "") {
        //e.target.value就是录入的内容
        this.phoneMemoryId = e.target.value;
      }
    },
    //查询串号
    serchFunction(imei) {
      const { href } = this.$router.resolve({
        path: "/RecallOrder/index",
        query: {
          imei: imei,
        },
      });
      window.open(href, "_blank");
    },
    //人工审单商户
    Manuaexamination() {
      this.SetcategoryDloding = true;
      this.setType = "00";
      this.gethavecompanyConfig();
    },
    //商户配置列表
    gethavecompanyConfig() {
      _api.havecompanyConfig().then((res) => {
        if (res.code == 1) {
          this.setlistData = res.data;
        }
      });
    },
    // 移除商户
    delClick(row) {
      this.paramData.companyIdRemove = [];
      if (row) {
        //单个移除
        this.paramData.companyIdRemove.push(row.companyId);
      } else {
        //批量移除
        this.multipleSelection.forEach((item) => {
          this.paramData.companyIdRemove.push(item.companyId);
        });
      }
      console.log(this.paramData);
      this.sublicenseDialogVisible = true;
    },
    // 谷歌验证
    Addpartitle() {
      if (this.checkList.length === 0) {
        return this.$message.error("请选择合作商！");
      }
      this.tantDialogVisible = true;
    },
    onSubmit() {
      let params = {
        pageNum: 1,
        pageSize: 5,
        companyName: this.username,
      };
      this.Addcommercialtenant(1, params);
    },
    // 添加商户
    handleCurrentChange2(val) {
      if (val) {
        this.page2.pageNum = val;
      } else {
        this.page2.pageNum = 1;
      }
      let params = {
        pageNum: this.page2.pageNum,
        pageSize: 5,
        companyName: this.username,
      };
      _api.companyList(params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.tableData = res.data.records;
          this.page2.pageSize = res.data.size;
          this.page2.total = res.data.total;
          this.page2.pageNum = res.data.current;
        }
      });
    },
    //addpar close
    Addpartnerclose() {
      this.username = "";
      this.Addpartnershow = false;
      this.checkList = [];
    },
    //添加商户
    Addcommercialtenant(e, paramss) {
      this.Addpartnershow = true;
      this.checkData = null;
      let params = {};
      if (paramss) {
        params = paramss;
      } else {
        params = {
          pageNum: 1,
          pageSize: 5,
        };
      }
      console.log(params);
      _api.companyList(params).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.records;
          this.page2.pageSize = res.data.size;
          this.page2.total = res.data.total;
          this.page2.pageNum = res.data.current;
        }
      });
    },
    handleDelete(row) {
      console.log(row);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
    cooperationChange(val) {
      this.checkList = val;
      console.log(val);
    },
    setTyClick(type) {
      this.setType = type;
      //商户
      if (this.setType == "00") {
        this.gethavecompanyConfig();
      } else {
        //订单
        this.setQuotecategory();
      }
    },
    // 设置收货方弹框关闭
    receiptTypeClosed() {
      this.command = "";
    },
    //按订单配置
    setQuotecategory() {
      this.getQuoteAssignTemp();
    },
    //获取报价配置模板
    getQuoteAssignTemp() {
      //清空
      this.resetalllist();
      _api.orderConfig().then((res) => {
        this.alltypeData = res.data.typeList;
        this.allQuteData = res.data;
        //按旧机预估价配置
        this.machinePrePricecities = res.data.machinePrePrice;
        res.data.machinePrePrice.forEach((item) => {
          this.machinePrePriceOptions.push(item.value);
          if (item.selected) {
            this.machinePrePricecheckedCities.push(item.value);
          }
        });
        //选择旧机使用情况
        this.quoteMachineHealthcities = res.data.quoteMachineHealth;
        res.data.quoteMachineHealth.forEach((item) => {
          this.quoteMachineHealthOptions.push(item.value);
          if (item.selected) {
            this.quoteMachineHealthcheckedCities.push(item.value);
          }
        });
        this.SetcategoryDloding = true;
      });
    },
    //清空报价品类
    resetalllist() {
      //预估价配置
      this.machinePrePricecheckAll = false;
      this.machinePrePricecheckedCities = [];
      this.machinePrePricecities = [];
      this.machinePrePriceisIndeterminate = true;
      this.machinePrePriceOptions = [];
      //选择旧机使用机况
      this.quoteMachineHealthcheckAll = false;
      this.quoteMachineHealthcheckedCities = [];
      this.quoteMachineHealthcities = [];
      this.quoteMachineHealthisIndeterminate = true;
      this.quoteMachineHealthOptions = [];
    },
    //确认保存报价配置
    quotationSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      let QuoteConfigparams = {
        prePrice: [], //	预估价配置（key值）
        machineHealth: [], //指定报价旧机使用情况
        command: this.command, //谷歌验证码
        typeList:JSON.parse(JSON.stringify(this.allQuteData)).typeList,//分类
      };
      //	旧机使用情况（key值）
      this.quoteMachineHealthcheckedCities.forEach((itema) => {
        this.quoteMachineHealthcities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.machineHealth.push(itemb.key);
          }
        });
      });
      //	预估价配置（key值）
      this.machinePrePricecheckedCities.forEach((itema) => {
        this.machinePrePricecities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.prePrice.push(itemb.key);
          }
        });
      });
      //	分类
       QuoteConfigparams.typeList.forEach((itema) => {
        itema.brandIds=[]//添加品牌id数组并置空
        itema.brands.forEach((itemb,index) => {
          if (itemb.selected) {
            itema.brandIds.push(itemb.id)
          }
        });
      });
    
      console.log(QuoteConfigparams);
      this.receiptTypeBtnLoading = true;
      //保存合作报价配置
      _api
        .saveOrder(QuoteConfigparams)
        .then((res) => {
          this.receiptTypeBtnLoading = false;
          this.quotationDialogVisible = false;
          this.SetcategoryDloding = false;
          this.command = "";
          this.$message({
            message: "配置成功！",
            type: "success",
          });
        })
        .catch((ree) => {
          this.receiptTypeBtnLoading = false;
        });
    },
    //保存合作报价配置
    SetcategorySubmit() {
      this.quotationDialogVisible = true;
    },
    //移除商户
    subjectSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      let subjiectprams = {
        command: this.command,
        companyIdAdd: [], //商户Id【新增的】
        companyIdRemove: this.paramData.companyIdRemove, //商户Id【删除的】
      };
      _api
        .saveCompany(subjiectprams)
        .then((res) => {
          this.sublicenseDialogVisible = false;
          this.receiptTypeBtnLoading = false;
          this.command = "";
          this.$message({
            message: "移除成功！",
            type: "success",
          });
          this.gethavecompanyConfig();
        })
        .catch((ree) => {
          this.receiptTypeBtnLoading = false;
        });
    },
    //保存商户审单配置
    tenantSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      let tenprams = {
        command: this.command,
        companyIdAdd: [], //商户Id【新增的】
        companyIdRemove: [], //商户Id【删除的】
      };
      this.checkList.forEach((res) => {
        tenprams.companyIdAdd.push(res.id);
      });
      this.saveCommercialtenant(tenprams);
    },
    //保存配置
    saveCommercialtenant(data) {
      _api
        .saveCompany(data)
        .then((res) => {
          this.receiptTypeBtnLoading = false;
          this.tantDialogVisible = false;
          this.Addpartnershow = false;
          this.command = "";
          this.$message({
            message: "配置成功！",
            type: "success",
          });
          this.gethavecompanyConfig();
        })
        .catch((ree) => {
          this.receiptTypeBtnLoading = false;
        });
    },
    //按旧机预估价配置
    machinePrePriceallChange(val) {
      console.log(val);
      this.machinePrePricecheckedCities = val
        ? this.machinePrePriceOptions
        : [];
      this.machinePrePriceisIndeterminate = false;
    },
    machinePrePriceChange(value) {
      console.log(value);
      let machinePrePriceCount = value.length;
      this.machinePrePricecheckAll =
        machinePrePriceCount === this.machinePrePricecities.length;
      this.machinePrePriceisIndeterminate =
        machinePrePriceCount > 0 &&
        machinePrePriceCount < this.machinePrePricecities.length;
    },
    //选择旧机使用情况
    quoteMachineHealthallChange(val) {
      console.log(val);
      this.quoteMachineHealthcheckedCities = val
        ? this.quoteMachineHealthOptions
        : [];
      this.quoteMachineHealthisIndeterminate = false;
    },
    quoteMachineHealthChange(value) {
      console.log(value);
      let quoteMachineHealthCount = value.length;
      this.quoteMachineHealthcheckAll =
        quoteMachineHealthCount === this.quoteMachineHealthcities.length;
      this.quoteMachineHealthisIndeterminate =
        quoteMachineHealthCount > 0 &&
        quoteMachineHealthCount < this.quoteMachineHealthcities.length;
    },
    handleClick(data, type) {
      //remind规范提醒  remark完美点评  reshot发起补拍 reject驳回报价
      this.orderNo = data.orderNo;
      if (type == "remind") {
        this.SpereminderShow = true;
      } else if (type == "remark") {
        this.commandShow = true;
      } else if (type == "reshot") {
        this.standSampleImageList(this.orderNo);
      } else {
        this.RejectofferShow = true;
      }
    },
    // 获取示例图（补拍）
    standSampleImageList(orderNo) {
      console.log(orderNo);
      _api.standSampleImageList({ orderNo }).then((res) => {
        if (res.code == 1) {
          this.reshootimgList = res.data;
          this.InitiatereshootShow = true;
        }
      });
    },
    //点评内容
    openExpected(data) {
      this.imgList = data;
      this.showViewer = true;
    },
    getindex(e) {
      this.imgShowIndex = e;
    },
    imgClose() {
      this.imgShow = false;
      this.imgList = [];
    },
    //补拍图片
    openImgBox(row, type) {
      if (type == 2) {
        let cvarr = [];
        row.forEach((item) => {
          cvarr.push(item.img);
        });
        this.DialogImgList = cvarr;
      } else {
        this.DialogImgList = row;
      }
      console.log(row);
      this.imgShow = true;
    },
    // 报价商家弹窗
    openStoreList(e) {
      this.merchantName = e.merchantName;
      _api.orderStoreList({ orderNo: e.orderNo }).then((res) => {
        if (res.code == 1) {
          for (const item of res.data) {
            this.handleData(item);
          }
          this.storeList = res.data;
        }
      });
      this.storeShow = true;
    },
    storeClose() {
      this.storeShow = false;
      this.amendantrecordshow = false;
      this.storeList = [];
    },
    rowStyle({ row, column, rowIndex, columnIndex }) {
      console.log(row);
      if (columnIndex == 6 && !(row.quoteNum == 0) && !(row.quoteNum == "--")) {
        return "text-decoration:underline;color:#21a4f1;cursor:pointer;";
      }
    },
    Bargain(row, column, cell, event) {
      if (
        !(row.quoteNum == 0) &&
        !(row.quoteNum == "--") &&
        column.property == "quoteNum"
      ) {
        console.log(row, "------------");
        this.viewrecords = true;
        _api
          .getUpdatedQuoteLog({
            merchantId: row.recycleMerchantId,
            orderNo: row.orderNo,
          })
          .then((res) => {
            console.log(res.data, "报价信息");
            this.getUpdatedQuoteLog = res.data.normal;
            this.remakeQuoteLog = res.data.remake;
          });
      }
    },
    viewrecordsClose() {
      this.viewrecords = false;
      this.pricePushshow = false;
    },
    // 所有不为真的第一层数据重置为--
    handleData(param) {
      for (const key in param) {
        if (Object.prototype.toString.call(param[key]) === "[object Object]") {
          this.handleData(param[key]);
        } else if (
          (!param[key] || param[key] == undefined || param[key] == "") &&
          param[key] !== 0
        ) {
          param[key] = "--";
        }
      }
      return param;
    },
    //关闭操作弹窗
    commandClosed() {
      console.log(1);
      this.isResponsibilityReset = true;
      this.PerfectreviewData.joinCause = "";
      this.PerfectreviewData.images = [];
      this.imgdetailData.state = "00";
      this.orderNo = "";
      this.ngImgidex = null;
    },
    //规范提醒图片
    standardChange(v) {
      console.log(v, "规范提醒图片");
      this.PerfectreviewData.images = v;
    },
    // 发起补拍
    judgementsChange(v) {
      console.log(v, "发起补拍");
      this.PerfectreviewData.images = v;
    },
    // 驳回报价
    rejectChange(v) {
      console.log(v, "驳回报价");
      this.PerfectreviewData.images = v;
    },
    //快捷备注
    remarkAdd(mark) {
      this.PerfectreviewData.joinCause =
        this.PerfectreviewData.joinCause + mark;
    },
    //状态切换
    btnClick(type) {
      this.auditStatus = type;
      //更新审单状态
      this.$store.commit("tagsView/STANDTPY", type);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    //旧机信息记录
    seaRecord(data) {
      this.Oldmachineinformation = data;
      _api
        .updatePhoneList({
          orderNo: data.orderNo,
        })
        .then((res) => {
          if (res.code === 1) {
            this.phoneRecoderList = res.data || [];
            this.amendantrecordshow = true;
          }
        });
    },
    //修改旧机信息
    resetoldmachine(data) {
      this.Oldmachineinformation = data;
      this.machineTypeId = data.typeId;
      //判断是否苹果系统
      _api
        .ResidentTable({
          pageNum: 1,
          pageSize: 999,
          brandNo: data.brandNo,
          machineTypeId: data.typeId,
        })
        .then((res) => {
          if (res.code === 1) {
            if (res.data.records.length) {
              this.flagIos = res.data.records[0].isIos === "01" ? true : false;
              this.orhaveIos =
                res.data.records[0].isIos === "01" ? true : false;
              console.log(this.flagIos);
            }
          }
        });

      this.serchbrand(); //查询品牌
      this.serchmodel(data.brandNo); //查询机型
      this.getMachineSpec(data.brandNo); //查询内存(运行，储存)
      //旧机信息回显
      this.brandNo = data.brandNo;
      this.imei = data.imei;
      this.modelCode = data.modelCode;
      this.phoneStorageId = data.phoneStorageId || data.phoneStorage;
      this.phoneMemoryId = data.phoneMemoryId || data.phoneMemory;
      this.csetionShow = true;
    },
    //查询机型
    serchmodel(brandNo) {
      _api
        .modelList({
          brandNo,
          pageNum: 1,
          pageSize: 999,
          machineTypeId: this.machineTypeId,
        })
        .then((res) => {
          if (res.code === 1) {
            this.phoneSelectList = res.data.records;
          }
        });
    },
    //查询内存(运行，储存)
    getMachineSpec(brandNo) {
      _api
        .getMachineSpec({
          brandNo,
          pageNum: 1,
          pageSize: 999,
          machineTypeId: this.machineTypeId,
        })
        .then((res) => {
          if (res.code === 1) {
            //规格  储存空间
            this.skuSelectList = res.data.memory || [];
            //运行内存
            this.operatSelectList = res.data.randomAccessMemory || [];
          }
        });
    },
    //查询品牌
    serchbrand() {
      _api
        .ResidentTable({
          pageNum: 1,
          pageSize: 999,
          machineTypeId: this.machineTypeId,
        })
        .then((res) => {
          if (res.code === 1) {
            this.shanpsSelectList = res.data.records || [];
          }
        });
    },
    // 确认修改旧机信息
    submitAdd() {
      if (!this.brandNo) {
        return this.$message.error("请选择旧机品牌！");
      }
      let changeparams = {
        brandNo: this.brandNo,
        imei: this.imei,
        modelCode: this.modelCode,
        orderNo: this.Oldmachineinformation.orderNo,
        phoneMemory: "",
        phoneMemoryId: this.phoneMemoryId,
        phoneStorage: "",
        phoneStorageId: this.phoneStorageId,
      };
      console.log(this.phoneStorageId, this.phoneMemoryId);
      if (typeof this.phoneStorageId == "number") {
        //规格，存储空间
        this.skuSelectList.forEach((item) => {
          if (item.id == this.phoneStorageId) {
            changeparams.phoneStorage = item.specName;
          }
        });
      } else if (typeof this.phoneStorageId == "string") {
        // if ((this.phoneStorageId.indexOf('G')===-1&&this.phoneStorageId.indexOf('T')===-1)||this.phoneStorageId=='其他容量') {
        //   return this.$message.error("单位输入错误！");
        // }
        if (this.phoneStorageId.length > 4) {
          return this.$message.error("规格或存储空间输入错误！");
        }
        changeparams.phoneStorageId = "";
        changeparams.phoneStorage = this.phoneStorageId;
      }
      if (typeof this.phoneMemoryId == "number" && !this.flagIos) {
        //运行内存
        this.operatSelectList.forEach((item) => {
          if (item.id == this.phoneMemoryId) {
            changeparams.phoneMemory = item.specName;
          }
        });
      } else if (typeof this.phoneMemoryId == "string" && !this.flagIos) {
        // if (this.phoneMemoryId.indexOf('G')==-1&&this.phoneMemoryId.indexOf('T')==-1) {
        //   return this.$message.error("单位输入错误！");
        // }
        if (this.phoneMemoryId.length > 4) {
          return this.$message.error("运行内存输入错误！");
        }
        changeparams.phoneMemoryId = "";
        changeparams.phoneMemory = this.phoneMemoryId;
      }
      //新估价模式不传规格、存储id
      if (this.Oldmachineinformation.orderMode == "00") {
        changeparams.phoneMemoryId = "";
        changeparams.phoneStorageId = "";
      }
      this.btnLoading = true;
      console.log(changeparams);
      _api
        .modifyPhoneInfo(changeparams)
        .then((res) => {
          if (res.code === 1) {
            this.csetionShow = false;
            this.$message({
              message: "修改成功！",
              type: "success",
            });
            this.handleCurrentChange(1);
          }
          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    selecthandleChange(e) {
      console.log(typeof e);
    },
    //品牌修改
    selecbrandChange(e) {
      console.log(e);
      //判断是否苹果系统
      _api
        .ResidentTable({
          pageNum: 1,
          pageSize: 999,
          brandNo: e,
          machineTypeId: this.machineTypeId,
        })
        .then((res) => {
          if (res.code === 1) {
            if (res.data.records.length) {
              this.flagIos = res.data.records[0].isIos === "01" ? true : false;
              console.log(this.flagIos);
            }
          }
        });
      this.modelCode = "";
      this.phoneStorageId = "";
      this.phoneMemoryId = "";
      this.serchmodel(e); //查询机型
      this.getMachineSpec(e); //查询内存(运行，储存)
    },
    //机型修改
    selectmaicelChange(e) {
      console.log(e);
    },
    // 功能情况
    getFunction(v, outType) {
      this.externalorderType = outType;
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        show: true,
        isLoadding: true,
      };
      // 功能情况详情
      this.getreportDetail(v);
    },
    // 功能情况接口
    getreportDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then((res) => {
        this.detailData = res.data;
        this.imeiTestProps.isLoadding = false;
      });
    },
    copy(id) {
      const spanText = document.getElementById(id).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
    },
    //操作确认
    usedSuccess(type) {
      //remind规范提醒  remark完美点评  reshot发起补拍 reject驳回报价
      console.log(type, this.ngImgidex, this.reshootimgList);
      if (!this.PerfectreviewData.joinCause) {
        this.$message.error("请输入内容！");
        return;
      }
      if (
        (this.PerfectreviewData.images.length === 0 ||
          !this.PerfectreviewData.images[0].url) &&
        type !== "remark" &&
        type !== "reshot"
      ) {
        this.$message.error("请上传图片！");
        return;
      }
      if (![0, 1, 2].includes(this.ngImgidex) && type === "remark") {
        this.$message.error("请选择图片！");
        return;
      }
      //公共参数
      let publicParams = {
        content: this.PerfectreviewData.joinCause,
        images: [],
        orderNo: this.orderNo,
        sampleIdList: [],
      };
      //发起补拍
      if (type === "reshot") {
        let numcount = 0;
        this.reshootimgList.forEach((item) => {
          if (item.isSelected) {
            numcount++;
            publicParams.sampleIdList.push(item.id);
          }
        });
        if (numcount === 0) {
          this.$message.error("请选择补拍图片！");
          return;
        }
      }
      //处理图片
      this.PerfectreviewData.images.forEach((item) => {
        if (item.url) {
          publicParams.images.push(item.url);
        }
      });
      console.log(publicParams);
      //remind规范提醒  remark完美点评  reshot发起补拍 reject驳回报价
      if (type === "remind") {
        this.getstandardorderList(publicParams);
      } else if (type === "remark") {
        let allarr = [];
        allarr.push(this.ncouragpictureList[this.ngImgidex]);
        publicParams.images = allarr;
        this.perfectorderList(publicParams);
      } else if (type === "reshot") {
        this.reimgorderList(publicParams);
      } else {
        this.rejectorderList(publicParams);
      }
    },
    //remind规范提醒
    getstandardorderList(data) {
      _api.standardorderList(data).then((res) => {
        if (res.code === 1) {
          this.SpereminderShow = false;
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.handleCurrentChange();
        }
      });
    },
    //remark完美点评
    perfectorderList(data) {
      _api.perfectorderList(data).then((res) => {
        if (res.code === 1) {
          this.commandShow = false;
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.handleCurrentChange();
        }
      });
    },
    //reshot发起补拍
    reimgorderList(data) {
      _api.reimgorderList(data).then((res) => {
        if (res.code === 1) {
          this.InitiatereshootShow = false;
          this.$message({
            message: "操作成功！",
            type: "success",
          });
          this.handleCurrentChange();
        }
      });
    },
    //reject驳回报价
    rejectorderList(data) {
      _api.rejectorderList(data).then((res) => {
        if (res.code === 1) {
          this.RejectofferShow = false;
          this.$message({
            message: "驳回成功！",
            type: "success",
          });
          this.handleCurrentChange();
        }
      });
    },
    // 查看示例图大图
    seeSampleImage(posterImg) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(posterImg);
    },
    //添加文案弹窗关闭
    resetForm() {
      this.csetionShow = false;
    },
    //   切换页
    handleCurrentChange(val) {
      this.fullscreenLoading = true;
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        estimateState: this.auditStatus,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        companyId: this.SeachParams.companyIdcopy,
        estimateAdminId: this.SeachParams.estimateAdminId,
        healthCode: this.SeachParams.healthCode,
        imei: this.SeachParams.imei,
        matchId: this.SeachParams.matchId,
        orderNo: this.SeachParams.orderNo,
        phoneName: this.SeachParams.phoneName,
        prePriceRegion: this.SeachParams.prePriceRegion || [],
        staffName: this.SeachParams.staffName,
        storeId: this.SeachParams.storeId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      console.log(this.SeachParams.startTime, this.entryTime);
      if (this.SeachParams.startTime) {
        baseRequest.startTime = this.SeachParams.startTime;
        baseRequest.endTime = this.SeachParams.endTime;
      } else {
        baseRequest.startTime = this.entryTime[0];
        baseRequest.endTime = this.entryTime[1];
      }
      this.loading = true;
      _api
        .estimateorderList(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            this.seachDataList = res.data.ipage.records;
            this.page.total = res.data.ipage.total;
            this.page.pageNum = res.data.ipage.current;
            this.allnumcount = res.data;
            this.loading = false;
            this.fullscreenLoading = false;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 上下架
    handleBtn(row) {
      this.copyNo = row.copyNo;
      this.isPut = row.isPut;
      this.usedDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.orderPagina {
  margin: 50px 50px 20px 0;
  text-align: right;
}
.autoshow::-webkit-scrollbar {
  display: none;
}

.emptyOrder {
  width: 100%;
  text-align: center;
  padding: 80px 0px;
  font-size: 24px;
  color: rgb(102, 102, 102);
  background: white;
  font-weight: bold;
}
.function_box {
  max-height: 700px;
  overflow: auto;

  .test-function {
    margin-top: 20px;
  }

  .fs16 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .mb10 {
    margin-bottom: 10px;
  }
}

.button_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .faultBtn {
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .none {
      text-align: center;
      color: #333;
      padding: 0 15px;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }

    .active {
      color: white;
      background: #0981ff;
    }
  }
}
.basi_information {
  span {
    margin-right: 20px;
  }
}
.cv_changgetext {
  margin-right: 10px;
}
.top_buttomsty {
  justify-content: flex-end;
  margin-bottom: 20px;
}
.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}

.text-down {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.staffNamebox {
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 5px;
  color: #fff;
}

.Encourag_picture {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    margin-right: 10px;
  }
}

.ep_image {
  position: relative;

  .imgredion {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
  }
}

.content_copy {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;
}

.qb_act {
  display: flex;
  margin: 10px 0;

  .qukliy_box {
    font-size: 13px;
    margin-right: 10px;
    line-height: 13px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #e4ecfd;
    color: rgb(9, 129, 255);
    cursor: pointer;
  }
}

.tip {
  color: #ff687b;
}
.top_title {
  color: #ff687b;
  font-size: 12px;
  margin-bottom: 10px;
}

.table {
  margin-top: 14px;

  .table_header {
    width: 100%;
    padding: 11px 0;
    padding-right: 10px;
    border-radius: 4px 4px 0px 0px;
    background: #e9f0fe;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table_header_left {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #4c84ff;
      justify-content: space-between;

      > p {
        margin-right: 30px;
      }

      .table_index {
        width: 30px;
        height: 18px;
        background: #f5b724;
        border-radius: 3px 9px 9px 3px;
        text-align: center;
        line-height: 18px;
        margin-right: 9px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .table_header_right {
      display: flex;

      .bicycle_lane {
        height: 20px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #21d8c2;
        border-radius: 10px;
        font-size: 12px;
        color: #21d8c2;
        padding: 0 11px;
        line-height: 20px;
      }

      .Star_support {
        margin-left: 10px;
        color: #ff9204;
        border: 1px solid #ffaf29;
      }
    }
  }

  /deep/ .el-table .el-table__header th {
    background: #f9fbfd !important;
    border-width: 0;
    border-bottom-width: 1px;
  }
}

.em_left {
  position: relative;
  width: 270px;
  padding: 20px 20px 20px 50px;
  min-height: 220px;
  background: #f5f6fa;
  border-radius: 4px;

  .eml_center {
    padding: 11px 0;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }

  .Current_information {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    width: 60px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    background: #21d8c2;
    border-radius: 8px 8px 8px 0px;
  }
}

.em_right {
  position: relative;
  width: 353px;
  padding: 20px;
  min-height: 220px;
  background: #e9f0ff;
  border-radius: 4px;

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  .change_information {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    width: 60px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    background: #4c84ff;
    border-radius: 8px 8px 8px 0px;
  }
}
.set_top_ck {
  span {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
  }
}
.addparto {
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #ff8080;
}

.categorySet {
  min-height: 82px;
  background: #ffffff;
  border: 1px solid #e4ecfd;
  border-radius: 10px;
  margin: 20px 0;

  .cs_center {
    padding: 0 25px;
    height: 34px;
    background: #eef3fe;
    border: 1px solid #e4ecfd;
    border-radius: 10px 10px 0px 0px;
  }
}
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  /deep/ .el-input {
    width: 300px;
  }
}
.selection_type {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  img {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
}

.dis_c {
  display: flex;
  align-items: center;
}

.ju_c {
  justify-content: center;
}

.ju_s {
  justify-content: space-between;
}

.mb-10 {
  margin-bottom: 10px;
}

.dis_flex {
  display: flex;
}

.al_c {
  align-items: center;
}

.ju_sb {
  justify-content: space-between;
}
</style>
